<template>
  <layout>
    <core-view class="primary" fill-height>
      <v-container id="containerlogo">
        <v-banner elevation="8" width="100%" color="#fafafa" single-line sticky>
          <div class="text-center inline-block" id="titulo">
            <p>Office Line Brasil</p>
            <p>Peanuts to the World</p>
          </div>

          <v-img
            contain
            src="/img/icons/brasil.png"
            max-width="80px"
            max-height="80vh"
            width="100%"
            id="imgtitulo"
            class="inline-block"
          >
          </v-img>
        </v-banner>
        <v-img
          contain
          src="/img/icons/peanutPlant-1000x1080.png"
          max-width="320px"
          max-height="80vh"
          id="imgamendoim"
        />
        <v-banner height="100px" :single-line="singleLineBool">
          <div
            id="tituloapp"
            class="display-2 font-weight-hign inline-block white--text text-center"
          >
            {{ appTitle }}
          </div>
          <v-icon class="icone" small color="white dark" dense>
            mdi-registered-trademark
          </v-icon>
        </v-banner>
      </v-container>

      <v-navigation-drawer
        app
        right
        stateless
        :value="true"
        :width="$_width"
        :color="$_background_color"
        :dark="$_isMobile"
      >
        <v-container justify-space-around align-center flex-column class='background'>
          <v-img
              contain
              src="/img/sol_logo2.png"
              max-height="15vh"
              width="100%"
              class='sol'
          />
          <div  v-if="$_isMobile">
            <!-- <v-banner
              elevation="8"
              width="100%"
              color="#ffffff"
              single-line
              sticky
              rounded="10"
            >
              <div class=" text-center inline-block black--text" id="titulo">
                <p>Office Line Brasil</p>
                <p>Peanuts to the World</p>
              </div>
              <v-img
                contain
                src="/img/icons/brasil.png"
                max-width="70px"
                max-height="80vh"
                width="100%"
                id="imgtitulo"
                class="inline-block"
              >
              </v-img>
            </v-banner> -->

            <div id='div-imagem'>
              <p class="text-center font-weight-bold">
                Officeline Brasil
              </p>
              <v-img
              contain
              src="/img/icons/peanutPlant-128x128.png"
              max-width="225px"
              width="32vw"
              id="imgamendoim"
              />
            </div>
            <v-banner class="text-center font-weight-bold black--text">
              {{ appTitle }}
              <v-icon x-small color="black dark" dense>
                mdi-registered-trademark
              </v-icon>
            </v-banner>
          </div>

          <div class='formulario-container'>
            <v-form
            ref="form"
            id="formulario"
            class="align-self-stretch"
            :disabled="loading"
            @submit.prevent="$_onSubmit"
          >
            <core-cpf-field
              v-model="model.cpf"
              type=tel
              pattern="\d*"
              label="CPF"
              :disabled="loading"
              :error-messages="errors.cpf"
              id='input-cpf'
              @focus='$_imputFocus'
              @blur='$_imputBlur'
              light
              autocomplete='off'
            />

            <core-password-field
              label="Senha"
              :disabled="loading"
              type=tel
              pattern="numeric"
              v-model="model.password"
              maxlength="20"
              id='input-password'
              @focus='$_imputFocus'
              @blur='$_imputBlur'
              light
            />

            <v-row class="my-0">
              <v-spacer />

              <v-btn
                rounded
                x-large
                type="submit"
                class="loginbtn rounded-r-1"
                :loading="loading"
                color="#4CAF50"
                :dark="$_isMobile"
                :style='cssBotao'
              >
                <v-icon left>mdi-login</v-icon>
                Entrar
                <!-- $t("labels.signIn") a versão EN está sendo selecionada por padrão,
                mudando para sign in -->
              </v-btn>
            </v-row>
          </v-form>
          </div>
          <!-- <v-overlay 
          v-if="($$_isIOSTablet || $$_isIOSMobile) && (!isSubbed || !$$_isStandalone)"
          >
            <v-dialog
            persistent
            width="500px"
            v-model="dialogPushIOS">
              <v-card>
                <v-card-title class="push__title">
                  <v-icon left dark>mdi-bell</v-icon>
                  Notificações
                </v-card-title>
                <br/>
                <v-card-text>
                  É necessario aceitar notificações para utilizar este aplicativo.
                </v-card-text>
                <v-card-actions class="push__actions" v-if="$$_isStandalone">
                    <v-btn 
                      color="primary"
                      @click="$_allowPushIOS">
                        Aceitar
                    </v-btn>
                </v-card-actions>
                <v-card-actions 
                class="push__actions" 
                v-else
                >
                    <v-btn 
                      color="primary"
                      disabled>
                        Adicione a tela inicial para continuar
                    </v-btn>
                </v-card-actions>
              <v-card-actions 
                class="push__actions" 
                v-if="!$$_isStandalone && !tutorialAdicaoTelaInicial"
                >
                  <b
                  :style="{ color: '#4CAF50' }"
                  @click="tutorialAdicaoTelaInicial = true"
                  >
                  Como fazer isso?
                  </b>
                </v-card-actions>
                <v-card-text v-if="tutorialAdicaoTelaInicial">
                  Procure por um icone  <v-icon>mdi-export-variant</v-icon>  na parte superior do navegador, selecione a opção "Adicionar a Tela Incial"
                  ou algo relacionado.
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-overlay> -->
          <v-container class='bkg_container'>
            <v-img
              contain
              src="/img/bkg_1.png"
              width="100%"
              class="plantstop animate"
              :style="hidePlant"
            />
            <v-img
              contain
              src="/img/bkg_2.png"
              width="100%"
              class="plantsbottom animate"
              :style="hidePlant"
            />
            <div :style="hidePlant" class='empresa animate'>
              <p>{{empresa}}</p>
              <!-- <b>{{$$_isIOSTablet}} {{$$_isIOSMobile}} {{isSubbed}} {{$$_isStandalone}}</b> -->
              <p>Versão BETA</p>
            </div>
          </v-container>
        </v-container>
      </v-navigation-drawer>
    </core-view>
  </layout>
</template>

<script>
import { ROUTE_HOME } from '@/constants/ROUTES';

import SnackbarEvent from '@/events/SnackbarEvent';

import actionResolver from '@/mixins/actions-resolver';
import CoreView from '@/components/core/view/Index.vue';
import CorePasswordField from '@/components/core/password-field/Index.vue';
import CoreCpfField from '@/components/core/cpf-field/Index.vue';
import { mapActions } from 'vuex';
import Layout from '@/layouts/base/Index.vue'
import { USER_STORAGE } from '@/constants/STORAGE'

import db from '@/plugins/dexie';

import { IsOnline, SetupIsOnline } from '../../utils/isonline';

import { checkExistingSubscriptionIOS, onlyCheck } from '@/registerServiceWorker';
import errorHandler from '@/utils/error-handler';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: 'ViewSignIn',

  components: {
    CoreView,
    CorePasswordField,
    CoreCpfField,
    Layout,
  },

  mixins: [
    actionResolver({
      $_login: 'auth/authenticate',
      $_loginLocal: 'auth/authenticateLocal',
      $_getId: 'users/findByWebId',
    }),
  ],

  data() {
    return {
      appTitle: process.env.VUE_APP_PROJECT_NAME,
      appVersion: process.env.VUE_APP_VERSION,

      loading: false,
      mobile: false,
      cssBotao: 'margin-bottom: 0vh;border-bottom: 0vh;right: 21%; width: 60%;',
      hidePlant: '',
      singleLineBool: false,

      model: {
        email: null,
        password: null,
      },

      errors: {},
      empresa: null,

      onLine: navigator.onLine,

      dialogPushIOS: true,

      isSubbed: true,

      tutorialAdicaoTelaInicial: false,
    };
  },

  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    $_width() {
      return this.$_isMobile ? window.screen.width : 512;
    },

    $_background_color() {
      return this.$_isMobile ? 'primary' : 'white';
    },

    $_btn_color() {
      return this.$_isMobile ? 'white' : 'primary';
    },

    $$_isIOSTablet() {
      return /iPad|iPhone|iPod|Mac/.test(navigator.userAgent) && !navigator.userAgent.includes('Mobile');
    },

    $$_isIOSMobile() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent);
    },

    $$_isStandalone() {
      if (this.$$_isIOSMobile || this.$$_isIOSTablet) {
        return navigator.standalone;
      }
      return window.matchMedia('(display-mode: standalone)').matches
    }
  },

  methods: {
    ...mapActions({
      $_login: 'auth/authenticate',
      $_empresa: 'empresa/find',
    }),

    async $_getEmpresa() {
      this.empresa = await this.$_empresa();
      this.empresa = this.empresa[0].empnome;
    },

    async $_onSubmit() {
      if (await IsOnline()) {
        this.$_onSubmitOnline();
      } else {
        console.log('ESTOU OFFLINE');
        if (!this.$refs.form.validate()) return;
        try {
          const user = await db.usuario.where({
            usecpf: this.model.cpf,
            usesenha: this.model.password,
          }).first();
          if (user) {
            sessionStorage.setItem(USER_STORAGE.useidnuvem, user.useidnuvem);
            await this.$_loginLocal();
            this.$router.replace(ROUTE_HOME.path);
          } else {
            document.dispatchEvent(
              new SnackbarEvent({
                message: 'Usuário ou Senha inválidos',
              }),
            );
          }
        } catch (err) {
          if (err.response?.status === 401 || err.response?.status === 422) {
            document.dispatchEvent(
              new SnackbarEvent({
                message: 'Erro ao logar',
              }),
            );
          }
          // errorHandler.call(this, err);
        }
      }
    },

    // Verifica somente se a permissao de push existe, sem fazer mais nenhuma outra logica
    async $_onlyCheck() {
      return await onlyCheck();
    },

    async $_onSubmitOnline() {
      console.log('ESTOU ONLINE');
      if (!this.$refs.form.validate()) return;
      try {
        await this.$_login(this.model);
        // setTimeout(() => {
        //   const token = getSessionStorage('auth:token', null);
        // }, 0);
        const user = await db.usuario.where({
          usecpf: this.model.cpf,
        }).first();
        const token = await getSessionStorage('auth:token', null);
        if (!user) {
          db.usuario.add({ 
            usenome: '---', 
            usecpf: this.model.cpf, 
            usesenha: this.model.password,
            useidnuvem: JSON.parse(token).userId
          });
        }
        sessionStorage.setItem(USER_STORAGE.useidnuvem, JSON.parse(token).userId);
        await this.$_loginLocal();
        this.$router.replace(ROUTE_HOME.path);
      } catch (err) {
        if (err.response?.status === 401 || err.response?.status === 422) {
          document.dispatchEvent(
            new SnackbarEvent({
              message: 'Usuario não cadastrado',
            }),
          );
        }
        // errorHandler.call(this, err);
      }
    },

    async $_allowPushIOS() {
      try {
        checkExistingSubscriptionIOS();
        const waitingForPush = setInterval(() => {
          const isSubbedPromise = this.$_onlyCheck();
          isSubbedPromise.then((value) => {
            this.isSubbed = value;
            if (value == true) {
              clearInterval(waitingForPush)
            }
          })
        }, 500);
      } catch (e) {
        errorHandler(e)
      }
      
    },

    $_imputFocus() {
      setTimeout(() => {
        const els = document.querySelectorAll('.animate');
        els.forEach((element) => {
          element.classList.add('fade');
        });
      }, 150);
    },

    $_imputBlur() {
      setTimeout(() => {
        const els = document.querySelectorAll('.animate');
        els.forEach((element) => {
          element.classList.remove('fade');
        });
      }, 150);
    },
  },

  created() {
    // setTimeout(() => {
    //   const isSubbedPromise = this.$_onlyCheck();
    //   isSubbedPromise.then((value) => {
    //     this.isSubbed = value;
    //   })
    // },1000);
    SetupIsOnline();
  },

};
</script>
<style scoped>

.background {
  background-color: #ffffff;
}

#containerlogo {
  padding: 0px;
  height: 100%;
  display: inline;
}

#titulo {
  float: right;
  padding: 5px;
}

#titulo p {
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}

#imgamendoim {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.icone {
  vertical-align: text-top;
  display: inline-block;
}

#tituloapp {
  display: inline-block;
  margin-left: 40%;
  padding: 5px;
}

/* ===== FORMULARIO ===== */

#formulario {
  margin-top: 10vh;
  min-height: 61vh;
  max-width: 525px;
  margin-top: 1vh;
  padding: 0px;
  border: 0px;
  flex-grow: 1;
}

.formulario-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* html, body { overflow:hidden; } */

#input-cpf {
  max-width: 525px;
}

#input-password {
  max-width: 525px;
}

.loginbtn {
  z-index: 999;
}

/* ===== FOOTER E PLANTAS ===== */

@keyframes sobePlanta {
  0% {
    transform: translateY(150%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes sobePlantaTABLET {
  0% {
    transform: translateY(150%);
  }
  100% {
    transform: translateY(25%);
  }
}

@keyframes desceSol {
  0% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateY(-15%);
  }
}

.sol {
  transform: translateY(-150%);
  animation: 1.2s desceSol 0.3s 1 forwards;
}

@media screen and (max-height: 730px) {
  .sol {
  display: none;
  }
}

.plantstop {
  transform: translateY(150%);
  position: absolute;
  bottom: 40px;
  left: 0px;
}

.plantsbottom {
  transform: translateY(150%);
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.empresa {
  transform: translateY(150%);
  position: absolute;
  bottom: 5px;
  padding: 0px 10px 0px 10px;
  left: 0px;
  color: white;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.animate {
  animation: 1.2s sobePlanta 0.3s 1 forwards;
}

.fade {
  opacity: 0;
}

.push__title {
  background-color: #4CAF50;
  color: white;
}

.push__actions {
  display: flex;
  justify-content: center;
}

</style>
